@import url(https://use.typekit.net/cee7ldq.css);
@import url(https://use.typekit.net/cee7ldq.css);
@import url(https://use.typekit.net/cee7ldq.css);
@import url(https://use.typekit.net/auu8wcu.css);
@import url(https://use.typekit.net/cee7ldq.css);
@import url(https://use.typekit.net/cee7ldq.css);
@import url(https://use.typekit.net/cee7ldq.css);
@import url(https://use.typekit.net/cee7ldq.css);


html, body {
	-webkit-hyphens: auto;
	    -ms-hyphens: auto;
	        hyphens: auto;
	text-justify: inter-word;
}

.custom-nav {
  background-color: #fff;
  height: 80px;
  border-bottom: 1px solid #e5e5e5;
  position: fixed;
  width: 100%;
  z-index: 999;
  /* Drop shadow */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
}

.nav-wrapper > div {
  object-fit: contain;
}

.nav-img {
  height: 64px;
  width: auto;
}

.search-input {
  border: 2px solid black;
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  width: 100%;
  text-align: center;
  /* Show light dropshadow on bottom */
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  outline: none;
}

.search-input::-webkit-input-placeholder {
  font-weight: bold;
  font-style: italic;
  color: black;
}

.search-img {
  display: none;
}

.search-mobile-header {
    height: 75px;
}

.search-results-desktop {
  position: absolute;
  top: 80px;
  right: calc(100% - 20px);
  left: calc(100% - 250px);
  text-align: center;
  background-color: rgb(241, 241, 241);
  width: 250px;
  box-shadow: -4px 4px 9px -6px #000000;
}

.search-result-link {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: black;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
}

.search-result-link-mobile {
    text-decoration: none;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
}

.search-modal-results-container {
    overflow-y: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    top: 102px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.search-modal-results-container > a:last-child {
    margin-bottom: 30px;
}

.search-modal-input-container {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.search-input-mobile {
    width: 100%;
    outline: none;
    border: none;
    height: 32px;
    background: rgba(112, 112, 112, 0.6);
    color: white;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
}

.search-modal-mobile {
    position: fixed;
    top: 80px;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgb(0, 0, 0, 0.4);
    color: white;
    z-index: 999;
    transition: all 0.3s ease-in-out;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    padding-top: 10px;
}

.search-modal-result {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    height: 64px;
    color: white;
    background: rgb(255, 255, 255, 0.2);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
}

.search-result-desktop {
  padding: 10px;
  border-bottom: 1px solid #e5e5e5;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-result-desktop:last-child {
  border-bottom: none;
}

@media screen and (max-width: 768px) {
  .search-results-desktop {
    display: none;
  }

  .search-input {
    display: none;
  }

  .nav-img {
    height: 32px;
    width: auto;
  }

  .search-img {
    display: block;
    height: 32px;
    width: auto;
  }
}

.nav-bottom {
  padding-bottom: 80px;
}

.credits-bg {
	background: rgb(255, 173, 125);
	background: linear-gradient(
		0deg,
		rgba(255, 173, 125, 1) 0%,
		rgba(204, 66, 0, 1) 60%,
		rgba(125, 0, 0, 1) 100%
	);
	min-height: 100vh;
	height: auto;
	padding-top: 20vh;
	padding-bottom: 25vh;
}

.credits {
	background: rgba(255, 255, 255, 0.6);
	min-height: 200vh;
	text-align: center;
	padding: 120px;
}

.thanks-line {
  line-height: 2;
	font-family: aktiv-grotesk, sans-serif;
	display: block;
	padding-bottom: 10px;
  text-align: left;
  letter-spacing: 0.1em;
}

.credit-line {
	line-height: 2;
	font-family: aktiv-grotesk, sans-serif;
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding-bottom: 10px;
}

.role-line {
	font-size: 1rem;
	font-weight: bold;
	color: black;
	text-transform: uppercase;
	text-align: left;
	letter-spacing: 0.1em;
	margin-bottom: 0.5rem;
}

.name-line {
	font-size: 1rem;
	color: black;
	text-align: right;
	letter-spacing: 0.1em;
	margin-bottom: 0.5rem;
  text-align: right;
}

.header {
	font-size: 4em;
	font-weight: bold;
	padding-bottom: 80px;
	font-family: imperial-urw, sans-serif;
	font-weight: 800;
	color: black;
}

.credit-separator {
  border-top: 1px solid black;
  margin-top: 20px;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
	.credits-bg {
		padding-top: 70px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 88px;
	}

	.credit-line {
		font-size: 0.9rem;
		display: block;
	}

	.name-line {
		background: rgba(0, 0, 0, 0.05);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    padding: 5px 10px;
    text-align: left;
    border-radius: 5px;
	}

  .role-line, .name-line {
    letter-spacing: normal;
  }

  .role-line::after {
    content: ":";
  }

	.header {
		font-size: 3em !important;
    padding-bottom: 10px !important;
	}

	.credits {
		padding: 20px 30px;
	}
}

.joinus-bg {
  background: rgb(255, 173, 125);
  background: linear-gradient(
    0deg,
    #002b3d 0%,
    #08111a 33%,
    #470b30 66%,
    #6a1148 100%
  );
  min-height: 100vh;
  height: auto;
  padding-top: 20vh;
  padding-bottom: 25vh;
}

.joinus {
  background: rgba(255, 255, 255, 0.6);
  min-height: 50vh;
  text-align: center;
  padding: 120px;
}

.join-us-text {
  font-family: aktiv-grotesk, sans-serif;
}

.header {
  font-size: 4em;
  font-weight: bold;
  padding-bottom: 80px;
  font-family: imperial-urw, sans-serif;
  font-weight: 800;
  color: black;
}

@media (max-width: 768px) {
  .joinus-bg {
    padding-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 88px;
  }

  .join-us-text {
    font-size: 0.9rem;
  }

  .header {
    font-size: 3em;
  }

  .header {
    padding-bottom: 20px;
  }

  .joinus {
    padding: 30px;
  }
}

.letter-editor-bg {
  background: rgb(255, 173, 125);
  background: linear-gradient(
    0deg,
    #D29A84 0%,
    #B86966 25%,
    #A97796 50%,
    #B86966 75%,
    #822E45 100%
  );
  min-height: 100vh;
  height: auto;
  padding-top: 20vh;
  padding-bottom: 25vh;
}

.letter-editor {
  background: rgba(255, 255, 255, 0.6);
  padding: 120px;
}

.letter-editor-text {
    font-size: 1.5rem;
    line-height: 1.5;
    font-family: aktiv-grotesk, sans-serif;
    margin: 10px;
}

.image-text-1 {
  float: right;
}

.letter-img {
  /* Dropshadow */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.header {
    font-size: 5em;
    font-weight: bold;
    padding-bottom: 80px;
    font-family: imperial-urw, sans-serif;
    font-weight: 800;
    color: black;
    text-align: center;
}

.letter-img-1 {
  width: 50%;
  height: auto;
  float: left;
  margin: 0px 20px 0px 0px;
}

.letter-img-2 {
  max-width: 50%;
  width: 30rem;
  height: auto;
  float: right;
  margin: 0px 0px 0px 20px;
}

.editor-line {
  float: right;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .letter-editor-bg {
    padding-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 88px;
  }

  .image-text-1 {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  
  .letter-img-1 {
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .letter-editor-text {
    font-size: 0.9rem;
  }

  .letter-img-2 {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .header {
      font-size: 3em;
  }

  .header {
      padding-bottom: 20px;
  }

  .letter-editor {
    padding: 30px 30px 70px 30px;
  }

  .editor-line {
    font-size: 1rem;
    float: right;
  }
}


.footer {
  background-color: white;
  padding-top: 60px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ccc;
}

.footer-logo {
  max-width: 200px;
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}

.footer-logo:hover {
  opacity: 0.6;
}

.footer-links {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  font-family: sweet-sans-pro, sans-serif;
}

.footer-links > div {
  padding-top: 10px;
}

.footer-divider {
  text-align: center;
  font-size: 2rem;
  font-weight: normal;
  font-weight: initial;
}

.social-links {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  margin-bottom: 50px;
}

.social-links > a > img {
  height: 32px;
  width: auto;
}

.social-links > a {
  margin: auto;
}

.footer-link {
  text-decoration: none;
  color: black;
  transition: all 0.3s ease-in-out;
}

.footer-link:hover {
  opacity: 0.5;
  color: black;
}

@media (max-width: 768px) {
  .footer {
    padding-left: 30px;
    padding-right: 30px;
  }
  .footer-links {
    grid-gap: 0;
    font-size: 1.2rem;
    font-weight: 800;
  }
  .footer-divider {
    font-size: 1.5rem;
  }
  .footer-links > div {
    padding-top: 5px;
  }
}

.header {
	font-size: 4em;
	font-weight: bold;
	padding-top: 5vh;
	padding-bottom: 5vh;
	font-family: imperial-urw, sans-serif;
	font-weight: 800;
	color: black;
}

.article-body {
	margin-left: 20vw;
	margin-right: 20vw;
	text-align: justify;
}

.article-body > .full-width-img,
.article-body > * > .full-width-img {
	width: calc(100% + 40vw);
	height: auto;
	margin-left: -20vw;
	margin-top: 5vh;
	margin-bottom: 5vh;
}

.featured-img {
	width: 100%;
	height: auto;
	margin-top: 3vh;
	margin-bottom: 5vh;
}

pullquote {
	font-size: 3em;
	font-family: imperial-urw, sans-serif;
	font-weight: 800;
	color: black;
	width: 100%;
	text-align: center;
}

pullquote::before {
	content: "“";
}

pullquote::after {
	content: "”";
}

.pullquote-container {
	width: 100%;
	text-align: center;
	margin-left: auto - 10vw;
	margin-right: auto;
	margin-top: 5vh;
	margin-bottom: 5vh;
}

.pullquote-container,
.masking-tape {
	background: url("https://hindsight-cdn.thevarsity.ca/hindsight/pull-quote-masking-tape.png")
		no-repeat;
	background-size: 100% 100%;
	padding-left: 200px;
	padding-right: 200px;
	min-height: 380px;
	display: flex;
	align-items: center;
	justify-content: center;
	object-fit: contain;
}

pullquote-container {
	background-size: 100% auto;
}

/* Flipbook CSS */

.flipbook-container {
	width: 60%;
	height: auto;
	position: relative;
	object-fit: scale-down;
	margin-top: 5vh;
	margin-bottom: 5vh;
	margin-left: auto;
	margin-right: auto;
}

.fb-page {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease-in-out;
}

.flipbook-button {
	outline: none;
	border: none;
	background: none;
}

.fb-left {
	position: absolute;
	left: -70px;
    top: calc(50% - 25px);
}

.fb-right {
	position: absolute;
	right: -70px;
    top: calc(50% - 25px);
}

.fb-page-number {
    position: absolute;
    top: 0%;
    right: 8px;
    color: black;
    transition: "opacity 0.5s ease-in-out"
}

@media screen and (max-width: 768px) {
	.article-body {
		margin-left: 20px;
		margin-right: 20px;
		text-align: justify;
	}
	.article-body > .full-width-img,
	.article-body > * > .full-width-img {
		width: calc(100% + 40px);
		height: auto;
		margin-left: -20px;
	}

	.featured-img {
		margin-top: 2vh;
		margin-bottom: 4vh;
	}

	pullquote {
		font-size: 1em;
		font-family: imperial-urw, sans-serif;
		font-weight: 800;
		color: black;
		width: 100%;
		text-align: center;
	}

	.pullquote-container {
		width: 100%;
		text-align: justify;
		padding-left: 40px;
		padding-right: 40px;
		padding-bottom: 20px;
		padding-top: 20px;
	}

	.pullquote-container,
	.masking-tape {
		background: url("https://hindsight-cdn.thevarsity.ca/hindsight/pull-quote-masking-tape.png")
			no-repeat;
		background-size: 100% 100%;
		min-height: auto;
		height: auto;
		object-fit: contain;
	}

	.flipbook-container {
		width: 80%;
		height: auto;
		margin-top: 5vh;
		margin-bottom: 5vh;
		margin-left: auto;
		margin-right: auto;
	}

	.flipbook-button {
		outline: none;
		border: none;
		background: none;
	}

	.fb-left {
		position: absolute;
		top: calc(50% - 25px);
		left: -50px;
	}

	.fb-right {
		position: absolute;
		top: calc(50% - 25px);
		right: -50px;
	}
}

.intro-card {
    background-color: #D29A84;
    padding: 30px;
    margin-bottom: 20%;
}

.intro-card-body { 
    background-color: #E9CFC4;
    padding: 20px 40px;
}

.blurb-img {
    width: 50%;
    height: auto;
    margin-top: -10%;
    margin-bottom: -10%;
    margin-left: 25%;
    margin-right: 25%;
}

.blurb-body {
    padding: 150px 60px;
}

.blurb-1 {
    background-color: #E9CFC4;
}

.blurb-2 {
    background-color: #D29A84;
}

.blurb-3 {
    background-color: #E1BCAE;
}

.visual-credit {
    font-size: 1em;
    font-family: "imperial-urw", sans-serif;
    font-weight: 800;
    color: black;
    text-align: center;
    margin: 5% 0;
}

@media screen and (max-width: 768px) {
    .intro-card {
        padding: 20px;
    }
    .intro-card-body {
        padding: 20px 20px;
    }
    .blurb-body {
        padding: 60px 20px;
    }
    .blurb-img {
        width: 70%;
        height: auto;
        margin-top: -15%;
        margin-bottom: -15%;
        margin-left: 15%;
        margin-right: 15%;
    }
}
.m-container {
	background-image: url("https://hindsight-cdn.thevarsity.ca/hindsight/a-day-with-tiktoks-magic-matt-bg-2.journey.jpg");
	background-repeat: repeat;
	background-size: cover;
	width: 100%;
	height: auto;
	padding: 30vh;
}

.m-body {
	background-color: rgba(3, 13, 50, 1);
	padding: 10vh;
	color: white;
	font-family: aktiv-grotesk, regular;
}

.gif-display {
    width: 100%;
    height: auto;
}

@media screen and (max-width: 768px) {
	.m-container {
		padding: 7vw;
	}

	.m-body {
		padding: 7vw;
	}
}
.math-container {
	color: white;
	padding-top: 3vh;
	padding-left: 10vw;
	padding-right: 10vw;
	padding-bottom: 1vh;
	font-family: aktiv-grotesk, regular;
	background: url("https://hindsight-cdn.thevarsity.ca/hindsight/sentimental-language-background.journey.jpg");
	background-repeat: no-repeat;
	background-position: center top;
}

.math-body {
	width: calc(100% - 20vw);
	padding: 5vh;
	margin: 7vh 10vw;
	background-color: rgba(125, 62, 81, 0.98);
	font-family: aktiv-grotesk, regular;
}

.math-subheader {
	width: 80%;
	height: auto;
	margin-left: 10%;
	margin-right: 10%;
	margin-top: -5vh;
}

.math-body > p:last-child {
	margin-bottom: 0;
}

.gif-display {
	width: 100%;
	height: auto;
}

@media screen and (max-width: 768px) {
	.math-container {
		padding-left: 1vw;
		padding-right: 1vw;
	}
	.math-body {
		margin: 5vw;
		width: calc(100% - 10vw);
		margin-bottom: 10vh;
	}
	.math-subheader {
		width: calc(100% + 10vw);
		height: auto;
		margin-left: -5vw;
		margin-right: -5vw;
		margin-bottom: 2vh;
		margin-top: -2vh;
	}
}

.sentimental-lang-credit {
	padding: 5vh 5vw;
	background: rgb(191, 110, 172);
	background: linear-gradient(
		0deg,
		rgba(191, 110, 172, 1) 0%,
		rgba(215, 194, 210, 1) 100%
	);
}

.ffyf-container {
	width: 100%;
	padding: 10vh 5vw;
	font-family: aktiv-grotesk, regular;
	background-color: rgb(245, 191, 153);
	font-size: 1.2rem;
}

.poem {
	margin-bottom: 5vh;
	background-image: url("https://hindsight-cdn.thevarsity.ca/hindsight/future-you-feared-texture.shift.svg");
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;
	width: 60%;
	padding: 5vh;
	margin-left: auto;
	margin-right: auto;
}

.audio-player {
	height: 64px;
	width: 100%;
	padding: 5px 10px;

	background: linear-gradient(
		to right,
		rgba(255, 175, 126, 1) 0%,
		19.506554305553436%,
		rgba(241, 113, 0, 1) 39.01310861110687%,
		69.50655430555344%,
		rgba(126, 0, 0, 1) 100%
	);
	display: flex;
	grid-gap: 20px;
	justify-content: space-between;
}

.audio-scrubber {
	width: 100%;
	/* Vertically center the scrubber */
	display: flex;
	align-items: center;
	justify-content: center;
}

.audio-player__time-display {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.2rem;
	font-family: aktiv-grotesk, regular;
	color: #fff;
	font-weight: bold;
	margin-right: 10px;
}

.audio-player__play-pause {
	height: 100%;
	width: 48px;
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	display: flex;
	justify-content: center;
	align-items: center;
}

.audio-scrubber > input[type="range"] {
	-webkit-appearance: none; /* Hides the slider so that custom slider can be made */
	width: 100%; /* Specific width is required for Firefox. */
	background: transparent; /* Otherwise white in Chrome */
}

.audio-scrubber > input[type="range"]:focus {
	outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

.audio-scrubber > input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 17px;
	height: 17px;
	margin-top: -7px;
	border-radius: 50%;
	background: rgb(255, 255, 255);
	cursor: pointer;
}

.audio-scrubber > input[type="range"]::-moz-range-thumb {
	-webkit-appearance: none;
	width: 16px;
	height: 16px;
	margin-top: -6px;
	border-radius: 50%;
	background: rgb(255, 255, 255);
	cursor: pointer;
}

.audio-scrubber > input[type="range"]::-webkit-slider-runnable-track {
	width: 100%;
	height: 3px;
	background: #fff;
	border-radius: 0px;
	cursor: pointer;
}

.audio-scrubber > input[type="range"]::-moz-range-track {
	width: 100%;
	height: 3px;
	background: #fff;
	border-radius: 0px;
	cursor: pointer;
}

.ffyf-toggle-script-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 5vh;
}

.ffyf-toggle-script {
	height: 48px;
	width: 144px;
	padding-left: 20px;
	padding-right: 20px;
	outline: none;
	border: none;
	background: rgb(241, 113, 0);
	background: linear-gradient(
		108deg,
		rgba(241, 113, 0, 1) 0%,
		rgba(255, 175, 126, 1) 54%
	);
	color: white;
	font-weight: bold;
}

@media screen and (max-width: 768px) {
	.poem {
		width: 100%;
	}
	.ffyf-container {
		padding: 5% 5%;
	}
}
