@import "https://use.typekit.net/auu8wcu.css";

.footer {
  background-color: white;
  padding-top: 60px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ccc;
}

.footer-logo {
  max-width: 200px;
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}

.footer-logo:hover {
  opacity: 0.6;
}

.footer-links {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  font-family: sweet-sans-pro, sans-serif;
}

.footer-links > div {
  padding-top: 10px;
}

.footer-divider {
  text-align: center;
  font-size: 2rem;
  font-weight: initial;
}

.social-links {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  margin-bottom: 50px;
}

.social-links > a > img {
  height: 32px;
  width: auto;
}

.social-links > a {
  margin: auto;
}

.footer-link {
  text-decoration: none;
  color: black;
  transition: all 0.3s ease-in-out;
}

.footer-link:hover {
  opacity: 0.5;
  color: black;
}

@media (max-width: 768px) {
  .footer {
    padding-left: 30px;
    padding-right: 30px;
  }
  .footer-links {
    grid-gap: 0;
    font-size: 1.2rem;
    font-weight: 800;
  }
  .footer-divider {
    font-size: 1.5rem;
  }
  .footer-links > div {
    padding-top: 5px;
  }
}
