.intro-card {
    background-color: #D29A84;
    padding: 30px;
    margin-bottom: 20%;
}

.intro-card-body { 
    background-color: #E9CFC4;
    padding: 20px 40px;
}

.blurb-img {
    width: 50%;
    height: auto;
    margin-top: -10%;
    margin-bottom: -10%;
    margin-left: 25%;
    margin-right: 25%;
}

.blurb-body {
    padding: 150px 60px;
}

.blurb-1 {
    background-color: #E9CFC4;
}

.blurb-2 {
    background-color: #D29A84;
}

.blurb-3 {
    background-color: #E1BCAE;
}

.visual-credit {
    font-size: 1em;
    font-family: "imperial-urw", sans-serif;
    font-weight: 800;
    color: black;
    text-align: center;
    margin: 5% 0;
}

@media screen and (max-width: 768px) {
    .intro-card {
        padding: 20px;
    }
    .intro-card-body {
        padding: 20px 20px;
    }
    .blurb-body {
        padding: 60px 20px;
    }
    .blurb-img {
        width: 70%;
        height: auto;
        margin-top: -15%;
        margin-bottom: -15%;
        margin-left: 15%;
        margin-right: 15%;
    }
}