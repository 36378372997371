html, body {
	hyphens: auto;
	text-justify: inter-word;
}

.custom-nav {
  background-color: #fff;
  height: 80px;
  border-bottom: 1px solid #e5e5e5;
  position: fixed;
  width: 100%;
  z-index: 999;
  /* Drop shadow */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
}

.nav-wrapper > div {
  object-fit: contain;
}

.nav-img {
  height: 64px;
  width: auto;
}

.search-input {
  border: 2px solid black;
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  width: 100%;
  text-align: center;
  /* Show light dropshadow on bottom */
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  outline: none;
}

.search-input::-webkit-input-placeholder {
  font-weight: bold;
  font-style: italic;
  color: black;
}

.search-img {
  display: none;
}

.search-mobile-header {
    height: 75px;
}

.search-results-desktop {
  position: absolute;
  top: 80px;
  right: calc(100% - 20px);
  left: calc(100% - 250px);
  text-align: center;
  background-color: rgb(241, 241, 241);
  width: 250px;
  -webkit-box-shadow: -4px 4px 9px -6px #000000;
  box-shadow: -4px 4px 9px -6px #000000;
}

.search-result-link {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: black;
    text-decoration-line: none;
}

.search-result-link-mobile {
    text-decoration: none;
    text-decoration-line: none;
}

.search-modal-results-container {
    overflow-y: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    top: 102px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.search-modal-results-container > a:last-child {
    margin-bottom: 30px;
}

.search-modal-input-container {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.search-input-mobile {
    width: 100%;
    outline: none;
    border: none;
    height: 32px;
    background: rgba(112, 112, 112, 0.6);
    color: white;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
}

.search-modal-mobile {
    position: fixed;
    top: 80px;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgb(0, 0, 0, 0.4);
    color: white;
    z-index: 999;
    transition: all 0.3s ease-in-out;
    backdrop-filter: blur(5px);
    padding-top: 10px;
}

.search-modal-result {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    height: 64px;
    color: white;
    background: rgb(255, 255, 255, 0.2);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
}

.search-result-desktop {
  padding: 10px;
  border-bottom: 1px solid #e5e5e5;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-result-desktop:last-child {
  border-bottom: none;
}

@media screen and (max-width: 768px) {
  .search-results-desktop {
    display: none;
  }

  .search-input {
    display: none;
  }

  .nav-img {
    height: 32px;
    width: auto;
  }

  .search-img {
    display: block;
    height: 32px;
    width: auto;
  }
}

.nav-bottom {
  padding-bottom: 80px;
}
