@import url("https://use.typekit.net/cee7ldq.css");

.credits-bg {
	background: rgb(255, 173, 125);
	background: linear-gradient(
		0deg,
		rgba(255, 173, 125, 1) 0%,
		rgba(204, 66, 0, 1) 60%,
		rgba(125, 0, 0, 1) 100%
	);
	min-height: 100vh;
	height: auto;
	padding-top: 20vh;
	padding-bottom: 25vh;
}

.credits {
	background: rgba(255, 255, 255, 0.6);
	min-height: 200vh;
	text-align: center;
	padding: 120px;
}

.thanks-line {
  line-height: 2;
	font-family: aktiv-grotesk, sans-serif;
	display: block;
	padding-bottom: 10px;
  text-align: left;
  letter-spacing: 0.1em;
}

.credit-line {
	line-height: 2;
	font-family: aktiv-grotesk, sans-serif;
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding-bottom: 10px;
}

.role-line {
	font-size: 1rem;
	font-weight: bold;
	color: black;
	text-transform: uppercase;
	text-align: left;
	letter-spacing: 0.1em;
	margin-bottom: 0.5rem;
}

.name-line {
	font-size: 1rem;
	color: black;
	text-align: right;
	letter-spacing: 0.1em;
	margin-bottom: 0.5rem;
  text-align: right;
}

.header {
	font-size: 4em;
	font-weight: bold;
	padding-bottom: 80px;
	font-family: imperial-urw, sans-serif;
	font-weight: 800;
	color: black;
}

.credit-separator {
  border-top: 1px solid black;
  margin-top: 20px;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
	.credits-bg {
		padding-top: 70px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 88px;
	}

	.credit-line {
		font-size: 0.9rem;
		display: block;
	}

	.name-line {
		background: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(10px);
    padding: 5px 10px;
    text-align: left;
    border-radius: 5px;
	}

  .role-line, .name-line {
    letter-spacing: normal;
  }

  .role-line::after {
    content: ":";
  }

	.header {
		font-size: 3em !important;
    padding-bottom: 10px !important;
	}

	.credits {
		padding: 20px 30px;
	}
}
