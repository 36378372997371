@import url("https://use.typekit.net/cee7ldq.css");

.joinus-bg {
  background: rgb(255, 173, 125);
  background: linear-gradient(
    0deg,
    #002b3d 0%,
    #08111a 33%,
    #470b30 66%,
    #6a1148 100%
  );
  min-height: 100vh;
  height: auto;
  padding-top: 20vh;
  padding-bottom: 25vh;
}

.joinus {
  background: rgba(255, 255, 255, 0.6);
  min-height: 50vh;
  text-align: center;
  padding: 120px;
}

.join-us-text {
  font-family: aktiv-grotesk, sans-serif;
}

.header {
  font-size: 4em;
  font-weight: bold;
  padding-bottom: 80px;
  font-family: imperial-urw, sans-serif;
  font-weight: 800;
  color: black;
}

@media (max-width: 768px) {
  .joinus-bg {
    padding-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 88px;
  }

  .join-us-text {
    font-size: 0.9rem;
  }

  .header {
    font-size: 3em;
  }

  .header {
    padding-bottom: 20px;
  }

  .joinus {
    padding: 30px;
  }
}
