@import url('https://use.typekit.net/cee7ldq.css');

.letter-editor-bg {
  background: rgb(255, 173, 125);
  background: linear-gradient(
    0deg,
    #D29A84 0%,
    #B86966 25%,
    #A97796 50%,
    #B86966 75%,
    #822E45 100%
  );
  min-height: 100vh;
  height: auto;
  padding-top: 20vh;
  padding-bottom: 25vh;
}

.letter-editor {
  background: rgba(255, 255, 255, 0.6);
  padding: 120px;
}

.letter-editor-text {
    font-size: 1.5rem;
    line-height: 1.5;
    font-family: aktiv-grotesk, sans-serif;
    margin: 10px;
}

.image-text-1 {
  float: right;
}

.letter-img {
  /* Dropshadow */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.header {
    font-size: 5em;
    font-weight: bold;
    padding-bottom: 80px;
    font-family: imperial-urw, sans-serif;
    font-weight: 800;
    color: black;
    text-align: center;
}

.letter-img-1 {
  width: 50%;
  height: auto;
  float: left;
  margin: 0px 20px 0px 0px;
}

.letter-img-2 {
  max-width: 50%;
  width: 30rem;
  height: auto;
  float: right;
  margin: 0px 0px 0px 20px;
}

.editor-line {
  float: right;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .letter-editor-bg {
    padding-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 88px;
  }

  .image-text-1 {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  
  .letter-img-1 {
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .letter-editor-text {
    font-size: 0.9rem;
  }

  .letter-img-2 {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .header {
      font-size: 3em;
  }

  .header {
      padding-bottom: 20px;
  }

  .letter-editor {
    padding: 30px 30px 70px 30px;
  }

  .editor-line {
    font-size: 1rem;
    float: right;
  }
}

