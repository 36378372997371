@import url("https://use.typekit.net/cee7ldq.css");

.header {
	font-size: 4em;
	font-weight: bold;
	padding-top: 5vh;
	padding-bottom: 5vh;
	font-family: imperial-urw, sans-serif;
	font-weight: 800;
	color: black;
}

.article-body {
	margin-left: 20vw;
	margin-right: 20vw;
	text-align: justify;
}

.article-body > .full-width-img,
.article-body > * > .full-width-img {
	width: calc(100% + 40vw);
	height: auto;
	margin-left: -20vw;
	margin-top: 5vh;
	margin-bottom: 5vh;
}

.featured-img {
	width: 100%;
	height: auto;
	margin-top: 3vh;
	margin-bottom: 5vh;
}

pullquote {
	font-size: 3em;
	font-family: imperial-urw, sans-serif;
	font-weight: 800;
	color: black;
	width: 100%;
	text-align: center;
}

pullquote::before {
	content: "“";
}

pullquote::after {
	content: "”";
}

.pullquote-container {
	width: 100%;
	text-align: center;
	margin-left: auto - 10vw;
	margin-right: auto;
	margin-top: 5vh;
	margin-bottom: 5vh;
}

.pullquote-container,
.masking-tape {
	background: url("https://hindsight-cdn.thevarsity.ca/hindsight/pull-quote-masking-tape.png")
		no-repeat;
	background-size: 100% 100%;
	padding-left: 200px;
	padding-right: 200px;
	min-height: 380px;
	display: flex;
	align-items: center;
	justify-content: center;
	object-fit: contain;
}

pullquote-container {
	background-size: 100% auto;
}

/* Flipbook CSS */

.flipbook-container {
	width: 60%;
	height: auto;
	position: relative;
	object-fit: scale-down;
	margin-top: 5vh;
	margin-bottom: 5vh;
	margin-left: auto;
	margin-right: auto;
}

.fb-page {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease-in-out;
}

.flipbook-button {
	outline: none;
	border: none;
	background: none;
}

.fb-left {
	position: absolute;
	left: -70px;
    top: calc(50% - 25px);
}

.fb-right {
	position: absolute;
	right: -70px;
    top: calc(50% - 25px);
}

.fb-page-number {
    position: absolute;
    top: 0%;
    right: 8px;
    color: black;
    transition: "opacity 0.5s ease-in-out"
}

@media screen and (max-width: 768px) {
	.article-body {
		margin-left: 20px;
		margin-right: 20px;
		text-align: justify;
	}
	.article-body > .full-width-img,
	.article-body > * > .full-width-img {
		width: calc(100% + 40px);
		height: auto;
		margin-left: -20px;
	}

	.featured-img {
		margin-top: 2vh;
		margin-bottom: 4vh;
	}

	pullquote {
		font-size: 1em;
		font-family: imperial-urw, sans-serif;
		font-weight: 800;
		color: black;
		width: 100%;
		text-align: center;
	}

	.pullquote-container {
		width: 100%;
		text-align: justify;
		padding-left: 40px;
		padding-right: 40px;
		padding-bottom: 20px;
		padding-top: 20px;
	}

	.pullquote-container,
	.masking-tape {
		background: url("https://hindsight-cdn.thevarsity.ca/hindsight/pull-quote-masking-tape.png")
			no-repeat;
		background-size: 100% 100%;
		min-height: auto;
		height: auto;
		object-fit: contain;
	}

	.flipbook-container {
		width: 80%;
		height: auto;
		margin-top: 5vh;
		margin-bottom: 5vh;
		margin-left: auto;
		margin-right: auto;
	}

	.flipbook-button {
		outline: none;
		border: none;
		background: none;
	}

	.fb-left {
		position: absolute;
		top: calc(50% - 25px);
		left: -50px;
	}

	.fb-right {
		position: absolute;
		top: calc(50% - 25px);
		right: -50px;
	}
}
