@import url("https://use.typekit.net/cee7ldq.css");

.m-container {
	background-image: url("https://hindsight-cdn.thevarsity.ca/hindsight/a-day-with-tiktoks-magic-matt-bg-2.journey.jpg");
	background-repeat: repeat;
	background-size: cover;
	width: 100%;
	height: auto;
	padding: 30vh;
}

.m-body {
	background-color: rgba(3, 13, 50, 1);
	padding: 10vh;
	color: white;
	font-family: aktiv-grotesk, regular;
}

.gif-display {
    width: 100%;
    height: auto;
}

@media screen and (max-width: 768px) {
	.m-container {
		padding: 7vw;
	}

	.m-body {
		padding: 7vw;
	}
}