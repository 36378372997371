@import url("https://use.typekit.net/cee7ldq.css");

.math-container {
	color: white;
	padding-top: 3vh;
	padding-left: 10vw;
	padding-right: 10vw;
	padding-bottom: 1vh;
	font-family: aktiv-grotesk, regular;
	background: url("https://hindsight-cdn.thevarsity.ca/hindsight/sentimental-language-background.journey.jpg");
	background-repeat: no-repeat;
	background-position: center top;
}

.math-body {
	width: calc(100% - 20vw);
	padding: 5vh;
	margin: 7vh 10vw;
	background-color: rgba(125, 62, 81, 0.98);
	font-family: aktiv-grotesk, regular;
}

.math-subheader {
	width: 80%;
	height: auto;
	margin-left: 10%;
	margin-right: 10%;
	margin-top: -5vh;
}

.math-body > p:last-child {
	margin-bottom: 0;
}

.gif-display {
	width: 100%;
	height: auto;
}

@media screen and (max-width: 768px) {
	.math-container {
		padding-left: 1vw;
		padding-right: 1vw;
	}
	.math-body {
		margin: 5vw;
		width: calc(100% - 10vw);
		margin-bottom: 10vh;
	}
	.math-subheader {
		width: calc(100% + 10vw);
		height: auto;
		margin-left: -5vw;
		margin-right: -5vw;
		margin-bottom: 2vh;
		margin-top: -2vh;
	}
}

.sentimental-lang-credit {
	padding: 5vh 5vw;
	background: rgb(191, 110, 172);
	background: linear-gradient(
		0deg,
		rgba(191, 110, 172, 1) 0%,
		rgba(215, 194, 210, 1) 100%
	);
}
