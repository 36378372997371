@import url("https://use.typekit.net/cee7ldq.css");

.ffyf-container {
	width: 100%;
	padding: 10vh 5vw;
	font-family: aktiv-grotesk, regular;
	background-color: rgb(245, 191, 153);
	font-size: 1.2rem;
}

.poem {
	margin-bottom: 5vh;
	background-image: url("https://hindsight-cdn.thevarsity.ca/hindsight/future-you-feared-texture.shift.svg");
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;
	width: 60%;
	padding: 5vh;
	margin-left: auto;
	margin-right: auto;
}

.audio-player {
	height: 64px;
	width: 100%;
	padding: 5px 10px;

	background: linear-gradient(
		to right,
		rgba(255, 175, 126, 1) 0%,
		19.506554305553436%,
		rgba(241, 113, 0, 1) 39.01310861110687%,
		69.50655430555344%,
		rgba(126, 0, 0, 1) 100%
	);
	display: flex;
	grid-gap: 20px;
	justify-content: space-between;
}

.audio-scrubber {
	width: 100%;
	/* Vertically center the scrubber */
	display: flex;
	align-items: center;
	justify-content: center;
}

.audio-player__time-display {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.2rem;
	font-family: aktiv-grotesk, regular;
	color: #fff;
	font-weight: bold;
	margin-right: 10px;
}

.audio-player__play-pause {
	height: 100%;
	width: 48px;
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	display: flex;
	justify-content: center;
	align-items: center;
}

.audio-scrubber > input[type="range"] {
	-webkit-appearance: none; /* Hides the slider so that custom slider can be made */
	width: 100%; /* Specific width is required for Firefox. */
	background: transparent; /* Otherwise white in Chrome */
}

.audio-scrubber > input[type="range"]:focus {
	outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

.audio-scrubber > input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 17px;
	height: 17px;
	margin-top: -7px;
	border-radius: 50%;
	background: rgb(255, 255, 255);
	cursor: pointer;
}

.audio-scrubber > input[type="range"]::-moz-range-thumb {
	-webkit-appearance: none;
	width: 16px;
	height: 16px;
	margin-top: -6px;
	border-radius: 50%;
	background: rgb(255, 255, 255);
	cursor: pointer;
}

.audio-scrubber > input[type="range"]::-webkit-slider-runnable-track {
	width: 100%;
	height: 3px;
	background: #fff;
	border-radius: 0px;
	cursor: pointer;
}

.audio-scrubber > input[type="range"]::-moz-range-track {
	width: 100%;
	height: 3px;
	background: #fff;
	border-radius: 0px;
	cursor: pointer;
}

.ffyf-toggle-script-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 5vh;
}

.ffyf-toggle-script {
	height: 48px;
	width: 144px;
	padding-left: 20px;
	padding-right: 20px;
	outline: none;
	border: none;
	background: rgb(241, 113, 0);
	background: linear-gradient(
		108deg,
		rgba(241, 113, 0, 1) 0%,
		rgba(255, 175, 126, 1) 54%
	);
	color: white;
	font-weight: bold;
}

@media screen and (max-width: 768px) {
	.poem {
		width: 100%;
	}
	.ffyf-container {
		padding: 5% 5%;
	}
}